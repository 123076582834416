export default {
    chart: {
        height: 320,
        type: 'radialBar',
    },

    colors: ['#82b1ff'],
    plotOptions: {
        radialBar: {
            hollow: {
                margin: 0,
                size: '70%',
                background: '#fff',
            },
            track: {
                dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 0,
                    blur: 4,
                    opacity: 0.15,
                },
            },
            dataLabels: {
                name: {
                    show: false,
                },
                value: {
                    color: 'primary-darken3',
                    fontSize: '52px',
                    show: true,
                },
            },
        },
    },
    fill: {
        type: 'gradient',
        gradient: {
            shade: 'dark',
            type: 'horizontal',
            gradientToColors: ['#ABE5A1', '#1B9DEC'],
            stops: [0, 100],
        },
    },

    labels: [''],
};
