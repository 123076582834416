<template>
    <v-card outlined class="pa-4">
        <v-card-text>
            <v-row class="pl-2 pt-2" align="start">
                <v-icon class="icon-account mr-3 pa-2">mdi-credit-card-refresh</v-icon>
                <v-col md="8" class="pa-0">
                    <span class="card-title">{{ title }}</span>
                </v-col>
            </v-row>
            <v-col md="10" align="start" class="card-content">
                <sup class="card-symbol font-weight-light">R$</sup>
                <span class="card-value font-weight-light">{{ amount }}</span>
            </v-col>
            <v-row class="ma-1">
                <v-icon class="mr-1">mdi-calendar-sync</v-icon>
                <span class="card-date">Atualização: {{ date }}</span>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props: {
        amount: {
            type: String,
        },
        date: {
            type: String,
        },
        title: {
            type: String,
        },
    },
};
</script>
<style lang="scss" scoped>
.v-icon.icon-account {
    width: 34px;
    height: 34px;
    color: var(--v-secondary-base);
    font-size: 1.5rem;
    border: 2px solid var(--v-secondary-base);
    border-radius: 2rem;
    padding: 0.25rem;
}
.v-icon.icon-card {
    color: var(--v-secondary-base);
    padding: 0.25rem;
    vertical-align: bottom;
}
.card-date {
    font-size: 0.75rem;
}
.card-title {
    font-size: 14px;
}

.card-symbol {
    font-size: 12px;
}

.card-value {
    font-size: 24px;
}
.card-content {
    margin-left: 2.5rem;
    margin-top: -1rem;
}
</style>
